import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from '../components/Loading';
// Vistas Front end
const Login = lazy(() => import('../views/Login'));
const Certificado = lazy(() => import('../views/Certificado'));
const Home = lazy(() => import('../views/Home'));

// Modulo 1
const Modulo1 = lazy(() => import('../views/Modulo1'));
const Video = lazy(() => import('../views/Modulo1/Video'));
const Evaluacion = lazy(() => import('../views/Modulo1/Evaluacion'));
const Elearning = lazy(() => import('../views/Modulo1/Elearning'));

// Modulo 2
const Modulo2 = lazy(() => import('../views/Modulo2'));
const VideoM2 = lazy(() => import('../views/Modulo2/Video'));
const EvaluacionM2 = lazy(() => import('../views/Modulo2/Evaluacion'));
const ElearningM2 = lazy(() => import('../views/Modulo2/Elearning'));

// Modulo 3
const Modulo3 = lazy(() => import('../views/Modulo3'));
const VideoM3 = lazy(() => import('../views/Modulo3/Video'));
const EvaluacionM3 = lazy(() => import('../views/Modulo3/Evaluacion'));
const ElearningM3 = lazy(() => import('../views/Modulo3/Elearning'));

// Vistas Back end
const LoginAdministrador = lazy(() => import('../views/Adminstration/Login'));
const HomeAdministrador = lazy(() => import('../views/Adminstration/Home'));
const Usuarios = lazy(() => import('../views/Adminstration/Usuarios'));
const UsuariosCrear = lazy(() => import('../views/Adminstration/Usuarios/Crear'));
const UsuariosActualizar = lazy(() => import('../views/Adminstration/Usuarios/Actualizar'));

export default function App() {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route exact path='/' element={<Home />} />
					{/* <Route exact path="/home" element={<Home />} /> */}
					<Route exact path='/certificado' element={<Certificado />} />
					{/* <Route exact path="/registrate" element={<Registro />} /> */}

					{/* Modulo1 */}
					<Route exact path='/modulo-uno' element={<Modulo1 />} />
					<Route exact path='/modulo-uno/bienvenida' element={<Video />} />
					<Route exact path='/modulo-uno/importancia-del-liderazgo-seguro' element={<Elearning />} />
					<Route exact path='/modulo-uno/evaluacion-modulo-uno' element={<Evaluacion />} />

					{/* Modulo2 */}
					<Route exact path='/modulo-dos' element={<Modulo2 />} />
					<Route exact path='/modulo-dos/bienvenida' element={<VideoM2 />} />
					<Route exact path='/modulo-dos/importancia-del-liderazgo-seguro' element={<ElearningM2 />} />
					<Route exact path='/modulo-dos/evaluacion-modulo-dos' element={<EvaluacionM2 />} />

					{/* Modulo3 */}
					<Route exact path='/modulo-tres' element={<Modulo3 />} />
					<Route exact path='/modulo-tres/bienvenida' element={<VideoM3 />} />
					<Route exact path='/modulo-tres/importancia-del-liderazgo-seguro' element={<ElearningM3 />} />
					<Route exact path='/modulo-tres/evaluacion-modulo-tres' element={<EvaluacionM3 />} />

					{/* Back end⁄ */}
					<Route exact path='/admin/login' element={<LoginAdministrador />} />
					<Route exact path='/admin/dashboard' element={<HomeAdministrador />} />
					<Route exact path='/admin/usuarios' element={<Usuarios />} />
					<Route exact path='/admin/usuarios/crear' element={<UsuariosCrear />} />
					<Route exact path='/admin/usuarios/:id/actualizar' element={<UsuariosActualizar />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}
